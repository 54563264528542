@import './App.less';

.neggymarge {
  margin-top: -40px;
}
.lets-chat {
  padding: 10rem 0;
  justify-content: center;

  &__info {
    > p {
      margin: 20px 0 0;
      font-size: 16px;
      font-family: @Lato;
    }

    .link-contact {
      display: grid;
      line-height: 1;

      h5 {
        font-size: 25px;
        font-family: @PT;
        line-height: 3;
        color: gray;
      }

      > span {
        padding: 30px;
        border: 1px solid #707070;
        margin-right: 50px;
        &:first-of-type {
          border-bottom: none;
        }
        &:last-of-type {
        }

        a {
          color: #363636;
          font-family: @Lato;
          font-size: 25px;

          span {
            vertical-align: middle;
          }
        }
      }
    }
    .booking-support {
      display: grid;
      line-height: 4;
      margin-top: 50px;

      h5 {
        font-size: 25px;
        font-family: @PT;
        line-height: 3;
        color: gray;
      }

      > span {
        padding: 0;
        border: 1px solid #707070;
        margin-right: 50px;
        margin-top: 0;

        a {
          color: #363636;
          font-family: @Lato;
          font-size: 25px;

          span {
            vertical-align: middle;
          }
        }
      }
    }
  }
}


@import './App.less';

.ant-col-24 {
  flex: none;
}

.header {
  display: flex;
  height: 80vh;
  align-items: center;

  h2 {
    font-size: 4rem;
    font-family: @PT;
    font-weight: bold;
    margin-bottom: 0;
    color: #fff;
    text-align: left;
    line-height: 4.5rem;
    margin-left: 8.33333333%;
  }
}

.ourServices {
  height: fit-content;
  position: relative;

  h4 {
    font-size: 1.25rem;
  }

  h5 {
    font-size: 1rem;
    margin-top: 1rem;
  }
}

.ourServicesLeft {
  margin-top: 30px;

  p {
    font-family: @Frank;
    font-size: 18px;
    color: #363636;
    line-height: 1.5rem;
    margin-top: 2.5rem;
  }
  a {
    margin-top: 2.5rem;
  }
  .getStarted {
    display: flex;
    justify-content: start;
    align-content: center;
    margin: 2rem;

    a {
      margin-top: 1.3rem;
      margin-left: -1.5rem;

      h4 {
        font-family: @PT;
        font-weight: bold;
      }
    }
  }

  .yellowBox {
    display: flex;
    top: 1067px;
    left: 122px;
    width: 50px;
    height: 50px;
    transform: matrix(0.71, -0.71, 0.71, 0.71, 0, 0);
    border: 2px solid #b7931c;
    opacity: 1;
    margin-top: 0.8rem;
  }
}

.ourServicesRight {
  background-color: #fff;
  margin-top: -70px;
  padding-top: 40px;
  padding-right: 80px;
  padding-left: 80px;

  h5 {
    margin-bottom: 2.5rem;
  }

  a {
    color: #363636;
    font-size: 17px;
    font-family: @Lato;
  }

  .dividerContainer {
    display: flex;
    justify-content: center;

    .rightDivider {
      border-color: #363636;
      opacity: 0.42;
      margin-top: 5rem;
      margin-bottom: 5rem;
    }

    .ant-divider-horizontal {
      width: 60%;
      min-width: 60%;
    }
  }
}
.svg-inline--fa.fa-w-14 {
  width: 4rem;
}

.svg-inline--fa.fa-w-20 {
  width: 2.5rem;
}

.servicesSeparator {
  height: 25vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .separator-vertical-services {
    width: 0;
    height: 100px;
    opacity: 0.42;
    border-color: #363636;
  }
  .squareServices {
    width: 9px;
    height: 9px;
    opacity: 0.46;
    background: #b7931c;
    transform: rotate(45deg);
    margin-bottom: 20px;
  }
  .separator-w-square-services {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.responsability {
  display: flex;
  height: auto;
  position: relative;
  justify-content: center;
  margin-top: 30px;

  border: 1px black solid;

  .responsabilityContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 70px 200px;
  }
  p {
    font-size: 16px;
    font-family: @Lato;
    color: #363636;
    margin-bottom: 3rem;
  }

  a {
    color: #363636;
  }

  .boxContainer {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;

    svg {
      margin-left: -56px;
      margin-top: 12px;
    }
  }
  .yellowBox {
    top: 1067px;
    left: 122px;
    width: 50px;
    height: 50px;
    transform: matrix(0.71, -0.71, 0.71, 0.71, 0, 0);
    border: 2px solid #b7931c;
    opacity: 1;
    margin-top: 0.8rem;
  }
}

.center {
  display: flex;
  align-content: center;
  flex-direction: column;
}

.ourClients {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 200px 0;

  .ourClientsImg {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    img {
      padding: 0 10px;
    }
  }

  h2 {
    margin-bottom: 1rem;
  }
}

.futureHoteliers {
  background-color: #363636;
  min-height: 100vh;
  margin-top: 100px;
  position: relative;
  padding-top: 50px;

  .futureContainer {
    position: relative;
    display: flex;
    // width: 100vw;
  }
  h2 {
    color: #fff;
    line-height: 4rem;
  }

  .futureImg {
    img {
      width: 100%;
      max-width: 600px;
      height: auto;
      top: -80px;
      position: absolute;
      z-index: 0;
      left: 10%;
    }
  }
}

.react-tabs {
  border: none;
  display: flex;
  width: 100vw;
  margin-top: 3rem;
}

.react-tabs__tab-list {
  background-color: transparent;
  color: #ffffff;
  margin-top: 2rem;
  border-bottom: none !important;
  display: flex;
  flex-direction: column;

  .react-tabs__tab--selected {
    border-bottom: 1px solid #b7931c !important;
    background-color: transparent;
    border-color: transparent;
  }
  .react-tabs__tab:focus {
    box-shadow: none;
    border-bottom-color: none;
  }
}

.react-tabs__tab {
  // padding: 8%;
  h5 {
    color: #ffffff;
  }
  margin: 1rem;
}

.react-tabs__tab-panel {
  background-color: #fff;
  opacity: 0.95;
  top: -15%;
  margin-left: 53px;
  padding: 2.5rem 3rem;
  height: 600px;
  position: relative;
  left: 100px;
  z-index: 2;
  overflow-y: scroll;

  h5 {
    font-weight: bold;
    font-family: @PT;
  }

  p {
    font-size: 16px;
    font-family: @Lato;
    color: #363636;
    opacity: 0.78;
  }

  a {
    color: #363636;
    font-size: 17px;
    font-family: @Lato;
  }
}

.separator-vertical-services {
  margin: 0;
  height: 75px;
  width: 100px;
  opacity: 0.42;
  top: 0;
  border-color: #ffffff;
}
.square {
  width: 9px;
  height: 9px;
  display: block;
  opacity: 0.46;
  background: #b7931c;
  transform: rotate(45deg);
}
.separator-w-square-services {
  text-align: center;
  .separator-vertical {
    margin: 0;
    top: -6px;
  }
}

// Media Queries

@media (max-width: 1199.98px) {
  .responsability {
    .responsabilityContainer {
      padding: 50px 100px;
    }
  }
  .ourClients {
    .ourClientsImg {
      img {
        margin: 0 5px;
      }
    }
  }
}

@media (max-width: 991.98px) {
  .ourServicesLeft {
    margin: 40px 40px;
  }
  .ourServicesRight {
    margin: 40px 40px;
    padding: 0;
  }

  .responsability {
    .responsabilityContainer {
      padding: 30px 60px;
    }
  }
  .ourClients {
    .ourClientsImg {
      img {
        padding: 10px 0;
      }
    }
  }
  .react-tabs__tab-panel {
    margin: 0;
    top: -50px;
  }
}

@media (max-width: 767.98px) {
  .ourServices {
    border: 1px solid transparent;
  }
  .ourServicesLeft {
    margin: 40px 40px;
  }

  .ourServicesRight {
    margin: 40px 40px;
    padding: 0;
  }

  .ourClients {
    .ourClientsImg {
      img {
        padding: 10px 0;
      }
    }
  }
  .futureContainer {
    h2 {
      font-size: 30px;
    }
    h5 {
      font-size: 20px;
    }
  }
  .react-tabs__tab-panel {
    margin: 0;
    top: -80px;
    left: 30px;
  }
}
@media (max-width: 575.98px) {
  .header {
    h2 {
      font-size: 40px;
      line-height: 45px;
    }
  }
  .ourServicesLeft {
    .yellowBox {
      margin: 0;
    }
    .getStarted {
      margin: 1rem;
      a {
        margin-top: 1rem;
      }
      .svg-inline--fa.fa-w-14 {
        width: 0.875rem;
      }
    }
  }
  .responsability {
    .responsabilityContainer {
      padding: 30px 30px;
    }
  }
  .ourClients {
    .ourClientsImg {
      img {
        padding: 5px 0;
        margin: 0 10px;
      }
    }
  }

  .futureContainer {
    h2 {
      font-size: 20px;
    }
    h5 {
      font-size: 15px;
    }
  }
  .react-tabs__tab-panel {
    padding: 10px;
    top: -120px;
    left: -20px;

    p {
      font-size: 13px;
    }
  }
}


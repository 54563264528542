.hoteliers-box {
  display: flex;
}

.hoteliers {
  display: flex;
  flex-direction: column;
  padding: 36px 0;
  width: 80%;
}

.hoteliers-banner {
  background-size: cover !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  h1 {
    color: #ffff;
    font-size: 25px;
    font-weight: 600;
    letter-spacing: 4px;
    text-transform: uppercase;
  }

  h2 {
    color: #ffff;
    font-size: 65px;
    font-weight: 600;
  }
}
.hoteliersContainer {
  width: 60%;
  margin-left: 7%;
  margin-top: 36px;
  margin-bottom: 36px;

  p {
    margin: 0;
  }

  ul {
    li {
      list-style: none;
    }
  }
}
.hoteliersContent {
  font-size: 16px;
  line-height: 24px;
  font-family: 'Lato', sans-serif;
  color: #363636;
}

.hoteliers-heading {
  font-size: 55px;
  color: #363636;
  margin-bottom: 24px;
}

.hoteliers-subheading {
  font-size: 20px;
  color: #363636;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: 500;
}

.hoteliers-button {
  color: #ffff;
  background-color: #e30000;
  border: none;
  padding: 12px 36px;
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  a {
    color: #ffff;
  }
}

.separator-vertical-hoteliers {
  width: 0;
  height: 75vh;
  opacity: 0.42;
  margin-bottom: 5px;
  border-color: #363636;
}

@media (max-width: 768px) {
  .hoteliers {
    width: 100%;
  }

  .hoteliersContainer {
    width: 85%;
  }

  .hoteliers-separator {
    display: none;
  }
}

@media (max-width: 425px) {
  .hoteliersContainer {
    width: 80%;
  }

  .hoteliers-heading {
    font-size: 36px;
  }
  .hoteliers-subheading {
    font-size: 20px;
  }

  .hoteliers-banner {
    h1 {
      font-size: 20px;
    }
    h2 {
      font-size: 36px;
    }
  }
}


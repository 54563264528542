@import './App.less';

.search-navigation-bar {
  margin: 25px @main-side-space 0;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d3d3d3;
  line-height: 1;

  &__logo {
    > svg {
      width: 17.78px;
      height: 22.1px;
      margin-right: 20px;
    }

    > span {
      color: #363636;
      font-family: @EuphemiaBold;
      font-size: 28px;
      letter-spacing: -0.84px;
    }
    img {
      width: 160px;
    }
  }

  nav {
    .ant-divider-vertical {
      margin: 0 15px;
      height: 34px;
      border-color: #707070;
    }
    .search {
      svg {
        color: #000000;
        font-size: 14px;
      }
    }
    .individual-booking,
    .location {
      padding-bottom: 5px;
      border-bottom: 1px solid #000000;
      svg:first-child {
        margin-right: 10px;
      }
      svg {
        color: #000000;
      }

      input {
        border: none;
        color: #000000;
        font-size: 14px;

        &::placeholder {
          color: #000000;
          font-size: 14px;
        }
      }
    }
    .check-this-out {
      a {
        color: #000000;
      }
      svg {
        margin-left: 15px;
        color: #e30000;
      }
    }
  }
  .menu-icon {
    cursor: pointer;
    align-self: center;
  }
}

//***************************//
// Media Queries
//***************************//
@media (max-width: 930px) {
  .search-navigation-bar {
    margin: 35px @main-side-space 0;
    padding-bottom: 30px;
    gap: 35px 0;

    &__logo {
      grid-column-start: 1;
      grid-row-start: 1;
    }
    nav {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 2;
      justify-self: center;
    }
    .menu-icon {
      align-self: center;
      justify-self: flex-end;
    }
  }
}

@media (max-width: 690px) {
  .search-navigation-bar {
    nav {
      display: grid;
      gap: 35px 0;

      .ant-divider-vertical {
        display: none;
      }

      .search {
        grid-column-start: 1;
        grid-row-start: 3;
      }
      .location {
        grid-row-start: 2;
      }
      .check-this-out {
        justify-self: flex-end;
        grid-column-start: 1;
        grid-row-start: 3;
      }
    }
  }
}

@media (max-width: 566px) {
  .search-navigation-bar {
    display: flex;
    flex-wrap: wrap;
  }
}


@import './App.less';

.main-image {
  .header-title {
    display: flex;
    justify-content: start;
    align-items: center;
    height: 70vh;

    h1 {
      font-size: 65px;
      color: #fff;
      font-family: @PT;
      font-weight: bold;
      line-height: 64px;
      // width: 100vw;
    }
  }
}

.who-we-are {
  margin-top: 50px;
  .align-left {
    span {
      font-size: 65px;
      font-weight: bold;
      font-family: @PT;
      color: #363636;
      line-height: 62px;
    }
  }
  .align-right {
    margin-top: 20px;

    span {
      font-size: 16px;
      line-height: 24px;
      font-family: @Lato;
      color: #363636;
    }
    p {
      font-size: 14px;
      color: #b7931c;
    }
  }
  .align-below {
    margin-top: 2rem;
    p {
      font-size: 14px;
      color: #b7931c;
      font-family: @Lato;
    }
  }
}

.our-mission {
  display: flex;
  width: 100%;
  position: relative;
  min-height: 50vh;
  margin-top: 15rem;

  .container {
    background-color: #fff;
    opacity: 0.95;
    position: relative;
    z-index: 3;
    width: 70vw;
    padding: 1rem;
  }
  .travelers {
    span {
      font-size: 20px;
      color: #363636;
      font-weight: 600;
      font-family: @Lato;
      letter-spacing: 3px;
    }
  }
  .hotel-portfolio {
    font-size: 55px;
    font-weight: bold;
    font-family: @PT;
    color: #363636;
    line-height: 53px;
    margin-bottom: 30px;
    margin-top: 20px;
  }
  .shoebottom {
    font-family: @Lato;
    font-size: 16px;
    color: #363636;
    margin-bottom: 25px;
    line-height: 24px;
  }
  .shoes {
    width: 80vw;
    height: 90vh;
    max-width: 600px;
    top: -150px;
    right: 0;
    position: absolute;
  }
  .ant-col {
    width: 100%;
  }
}

.kids {
  font-family: @Lato;
  color: #363636;

  .kidsImg {
    width: 100%;
    max-width: 500px;
    height: auto;
  }

  .kids-title {
    font-size: 20px;
    font-weight: 550;
  }
  .kids-content {
    margin-top: 20px;
    margin-bottom: 20px;
    line-height: 24px;

    font-size: 16px;
  }
  .kids-button {
    font-size: 17px;
    color: #363636;
    margin-left: 1rem;
  }
}

.marketing-image {
  margin-top: 4rem;

  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;

  .box {
    background-color: #fff;
    opacity: 0.95;

    padding-top: 1rem;
    padding-bottom: 3rem;
    padding-left: 4rem;
    padding-right: 4rem;
    margin-top: -3rem;
    position: absolute;
    right: -40rem;
  }
  .body_copy {
    width: 70vh;
  }

  .join_our_movement {
    margin-top: 4rem;

    font-family: @Lato;
    font-size: 20px;
    color: #363636;
    letter-spacing: 3px;
    font-weight: 600;
  }
  .join_market_move {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 55px;
    font-weight: bold;
    color: #363636;
    line-height: 53px;
  }
  .diamond {
    margin-top: 25px;
    font-family: @Lato;
    font-size: 20px;
    color: #363636;
    letter-spacing: 3px;
    font-weight: 600;
  }

  .tabs {
    p {
      font-size: 16px;
      font-family: @Lato;
      font-weight: 500;
      line-height: 24px;
      color: #363636;
      line-height: 24px;
    }
  }
  .ant-tabs {
    margin-top: 30px;
  }
  .ant-tabs-tab {
    font-size: 20px;
    font-family: @Lato;
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    color: #363636;
  }
  .ant-tabs-ink-bar {
    background: #b7931c;
  }
  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    border: none;
  }
  a {
    color: #363636;
    letter-spacing: 3px;
  }
}

.follow_lux {
  margin-bottom: 3rem;
  justify-content: center;
  text-align: center;

  a {
    color: #ff2800;
  }

  .follow_us {
    font-size: 55px;
    font-weight: bold;
    font-family: @PT;
    color: #363636;
  }

  .at_lux {
    font-size: 20px;
    color: #ff2800;
    font-weight: 600;
    font-family: @Lato;
    letter-spacing: 2px;
  }

  .grid-images {
    width: 100%;
    iframe {
      .js-powrMark {
        display: none;
      }
    }
  }
  .ant-row {
    justify-content: center;
  }
  .follow_image {
    margin: 20px;
    img {
      max-width: 233px;
      max-height: 233px;
      height: 100%;
      width: 100%;
    }
  }
}

.aboutSeparator {
  height: 25vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .separator-vertical-about {
    width: 0;
    height: 100px;
    opacity: 0.42;
    border-color: #363636;
    margin-bottom: 5px;
  }
  .separator-vertical-about-hero {
    width: 0;
    height: 40vh;
    opacity: 0.42;
    border-color: #363636;
    margin-bottom: 5px;
  }
  .squareAbout {
    width: 9px;
    height: 9px;
    opacity: 0.46;
    background: #b7931c;
    transform: rotate(45deg);
    margin-bottom: 20px;
  }
  .separator-w-square-about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 1599.98px) {
  .marketing-image {
    .box {
      right: -30rem;
    }
  }
}

@media (max-width: 1319.98px) {
  .marketing-image {
    .body_copy {
      width: auto;
    }
    .box {
      position: relative;
      right: 0;
    }
  }
}

@media (max-width: 1199.98px) {
  .marketing-image {
    .body_copy {
      width: auto;
    }
  }
}

@media (max-width: 1013.98px) {
  .follow_lux {
    .ant-row {
      width: 60vw;
    }
  }
  .marketing-image {
    .body_copy {
      width: auto;
    }
  }
}

@media (max-width: 991.98px) {
  .marketing-image {
    .box {
      position: relative;
      right: 0;
    }
  }

  .follow_lux {
    .ant-row {
      width: 60vw;
    }
  }

  .aboutSeparator {
    .separator-vertical-about-hero {
      height: 15vh;
    }
  }
}

@media (max-width: 790.98px) {
  .marketing-image {
    .body_copy {
      width: auto;
    }
  }
}

@media (max-width: 767.98px) {
  .marketing-image {
    .body_copy {
      width: auto;
    }
  }
}

@media (max-width: 682.98px) {
  .main-image {
    .header-title {
      h1 {
        font-size: 48px;
      }
    }
  }
  .who-we-are {
    .align-left {
      span {
        font-size: 48px;
      }
    }
  }

  .our-mission {
    .container {
      width: auto;
    }
    .travelers {
      span {
        font-size: 16px;
      }
    }
    .hotel-portfolio {
      font-size: 48px;
    }
    .shoes {
      background: none;
    }
  }

  .marketing-image {
    .box {
      padding: 2rem;
      margin-top: -6rem;
    }
    .join_market_move {
      font-size: 48px;
    }
    .body_copy {
      width: auto;
    }
  }
}

@media (max-width: 575.98px) {
  .main-image {
    .header-title {
      h1 {
        font-size: 30px;
      }
    }
  }

  .who-we-are {
    .align-left {
      span {
        font-size: 30px;
      }
    }
  }

  .our-mission {
    .travelers {
      span {
        font-size: 16px;
      }
    }
    .hotel-portfolio {
      font-size: 30px;
      line-height: 30px;
    }
  }

  .kids {
    img {
      width: 140px;
    }
  }

  .marketing-image {
    .join_our_movement {
      font-size: 16px;
    }
    .join_market_move {
      font-size: 30px;
    }
    .body_copy {
      width: auto;
    }

    .diamond {
      font-size: 16px;
    }
  }

  .follow_lux {
    .follow_us {
      font-size: 48px;
    }
    .at_lux {
      font-size: 18px;
    }
  }
}


@import './App.less';

.result-info {
  padding: 45px @main-side-space 0;

  &__type-of-hotel {
    font-family: @Lato;
    font-size: 16px;

    .term-searched {
      color: #b7931c;
    }
  }

  &__location-sort {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .place {
      color: #363636;
      font-family: @PT;
      font-size: 65px;
      font-weight: bold;
    }
    .sort-by {
      font-family: @Lato;
      font-size: 14px;

      span {
        font-weight: bold;
      }
      a {
        color: #363636;
        margin-left: 35px;
      }
    }
  }

  &__hotels {
    margin: 45px @main-side-space 0;

    .hotel {
      display: flex;

      &:not(:first-child) {
        margin-top: 55px;
      }

      .image {
        flex: 1;
        img {
          max-height: 420px;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .info {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 50px;
        flex: 1;
        -webkit-box-shadow: 3px 3px 20px #00000019;
        -moz-box-shadow: 3px 3px 20px #00000019;
        box-shadow: 3px 3px 20px #00000019;
        border: 1px solid #efefef;

        .header {
          flex-direction: column;
          align-items: baseline;
          .location,
          .price {
            font-family: @Lato;
            font-size: 14px;
          }
          .price {
            float: right;
          }
        }
        .body {
          margin-top: 20px;
          font-family: @Lato;
          font-size: 16px;
        }
        .bottom {
          margin-top: 45px;
          display: flex;
          justify-content: space-between;

          .categories {
            .category {
              padding: 10px;
              color: #b7931c;
              background: transparent;
              border-radius: 5px;
              text-transform: capitalize;
              font-weight: 600;
            }
          }
          .buttons {
            // padding: 10px 0;

            .details {
              padding: 10px;
              color: #ffffff;
              border-radius: 5px;

              svg {
                // margin-left: 10px;
                width: 2rem;
              }
            }
            .book {
              background: #4b4b4b;
            }
            .details {
              margin-left: 20px;
              background: #e30000;
            }
          }
        }
      }
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 5rem;

  .ant-pagination-item-active {
    border-color: #b7931c;
  }
  .ant-pagination-item-active {
    a {
      color: #b7931c;
    }
  }

  a:hover {
    color: #b7931c;
  }

  .ant-pagination-item:focus {
    border-color: #b7931c;
    color: #b7931c;
  }

  .ant-pagination-item:hover {
    border-color: #b7931c;
    color: #b7931c;
  }
}

.categories-section {
  margin: 100px;
  display: grid;
  justify-content: center;

  &_title {
    font-size: 24px;
    text-align: center;
    margin-top: 25px;
  }

  &__items-container {
    // width: fit-content;
    // margin: 95px auto 0;
    margin-top: 50px;
    display: grid;
    justify-items: center;
    gap: 42px 30px;
    grid-template-columns: repeat(3, 1fr);

    .item {
      position: relative;

      span:first-child::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0.37;
        background: #021825;
      }

      img {
        max-width: 250px;
        max-height: 500px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      span:nth-of-type(2) {
        a {
          width: fit-content;
          position: absolute;
          left: 0;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #ffffff;
          font-family: @Lato;
          font-size: 25px;
          text-transform: uppercase;
          text-align: center;
        }
      }
    }
  }

  &__items-container-2 {
    // width: fit-content;
    // margin: 95px auto 0;
    margin-top: 50px;
    display: grid;
    justify-items: center;
    gap: 42px 30px;
    grid-template-columns: repeat(4, 1fr);

    .item {
      position: relative;

      span:first-child::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0.37;
        background: #021825;
      }

      img {
        max-width: 250px;
        max-height: 500px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      span:nth-of-type(2) {
        a {
          width: fit-content;
          position: absolute;
          left: 0;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #ffffff;
          font-family: @Lato;
          font-size: 25px;
          text-transform: uppercase;
          text-align: center;
        }
      }
    }
  }
}

//***************************//
// Media Queries
//***************************//
@media (max-width: 1210px) {
  .result-info {
    &__hotels {
      .hotel {
        display: grid;

        .info {
          padding: 0;
          box-shadow: none;
        }
      }
    }
  }

  .categories-section {
    margin: 50px;
    h2 {
      text-align: center;
    }
  }
}

@media (max-width: 920px) {
  .result-info {
    &__location-sort {
      display: grid;
      gap: 25px 0;
    }
  }

  .categories-section {
    &__items-container {
      grid-template-columns: repeat(1, 1fr);
    }
    &__items-container-2 {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media (max-width: 575.98px) {
  .result-info {
    &__location-sort {
      .place {
        font-size: 45px;
      }
    }
  }

  .categories-section {
    margin: 24px;
    margin-bottom: 50px;
    &__items-container {
      grid-template-columns: repeat(1, 1fr);
    }
    &__items-container-2 {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}


@import './App.less';

.csrHeadlineSection {
  background-color: white;
  height: auto;
  // width: 60%;
  margin-top: -380px;
  padding-left: 100px;
  padding-right: 100px;
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  bottom: 0;
  opacity: 0.85;

  h2 {
    color: #000000;
    font-size: 4rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  h5 {
    line-height: 1.5rem;
    font-size: 1.25rem;
    margin-bottom: 30px;
  }
}

.topContainer {
  margin-top: 40px;
  // margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
}
.bottomContainer {
  margin-bottom: 60px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  h4 {
    margin-top: 15px;
  }
}

.topContainerSecond {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
}

.secondSection {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 50px;

  h2 {
    line-height: 3.5rem;
    margin-bottom: 2rem;
  }
  p {
    font-size: 16px;
    font-family: @Lato;
  }
  img {
    margin-top: -4rem;
    width: 100%;
  }
}
.textSection {
  margin-left: 6rem;
}

.secondImage {
  margin-top: -120px;
  margin-left: 2.5rem;
  padding-right: 4rem;
  padding-left: 4rem;
  display: flex;
  justify-content: center;
  img {
    width: 100%;
    max-width: 500px;
    height: 100%;
    z-index: 1;
  }
}

.csrCalculatorSection {
  margin-top: 100px;
  display: inline-block;
}

.caseStudies {
  display: flex;
  flex-wrap: wrap;

  p {
    font-size: 16px;
    margin-bottom: 3rem;
  }
  a {
    color: #b7931c;
  }
  .tabs {
    margin-top: 50px;
  }

  .ant-tabs-tab {
    font-size: 20px;
    color: #000000;
    border-bottom-color: #b7931c;
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    color: #000000;
  }

  .ant-tabs-ink-bar {
    background: #b7931c;
  }
}

.csrCalculatorSec {
  height: 70vh;
  display: flex;

  justify-content: center;
  align-content: center;
  align-items: center;
}

.separator-vertical-csr-left {
  margin-bottom: 10px;
  margin-top: -250px;
  height: 130px;
  opacity: 0.42;
  border-color: #363636;
}

.separator-vertical-csr-right {
  margin-bottom: 10px;
  height: 100vh;
  opacity: 0.42;
  // top: 0;
  border-color: #363636;
}
.squareCsr {
  width: 9px;
  height: 9px;
  display: flex;
  opacity: 0.46;
  background: #b7931c;
  transform: rotate(45deg);
  justify-content: center;
}
.separator-w-square-csr {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.video-responsive {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  padding-top: 30px;
  position: relative;
}
.video-responsive iframe,
.video-responsive object,
.video-responsive embed {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

// Media Queries

@media (max-width: 1546.98px) {
  .csrHeadlineSection {
    margin-top: -375px;
  }
}

@media (max-width: 1199.98px) {
  .csrBg {
    min-height: 100vh;
  }
  .csrHeadlineSection {
    margin-top: -470px;

    h2 {
      font-size: 3rem;
    }
  }
  .secondImage {
    margin-top: 30px;
  }
}

@media (max-width: 1077.98px) {
  .csrHeadlineSection {
    margin-top: -500px;
  }
}

@media (max-width: 991.98px) {
  .csrHeadlineSection {
    margin-top: -345px;
  }
}
@media (max-width: 767.98px) {
  .csrHeadlineSection {
    margin-top: -370px;
  }
  .separator-w-square-csr {
    height: 70vh;
  }
}
@media (max-width: 725.98px) {
  .csrHeadlineSection {
    height: auto;
    margin-top: -330px;
    padding: 0 30px;

    h4 {
      font-size: 15px;
    }

    h2 {
      font-size: 40px;
    }

    h5 {
      font-size: 17px;
    }
  }
  .textSection {
    margin-left: 2rem;
  }
}

@media (max-width: 575.98px) {
  .csrHeadlineSection {
    margin-top: -400px;
    padding: 40px;

    h4 {
      font-size: 15px;
    }

    h2 {
      font-size: 40px;
    }

    h5 {
      font-size: 17px;
    }
  }
}

@media (max-width: 523.98px) {
  .csrHeadlineSection {
    margin-top: -430px;
    padding: 0 30px;

    h4 {
      font-size: 15px;
    }

    h2 {
      font-size: 30px;
    }

    h5 {
      font-size: 17px;
    }
  }

  .textSection {
    h2 {
      font-size: 30px;
    }
  }
  .caseStudies {
    h2 {
      font-size: 20px;
    }
    .ant-tabs-tab {
      font-size: 14px;
    }
  }
}


@import './App.less';

//***************************//
// TOP Section of the home page
//***************************//

.homeHero {
  background-color: #ffff;
  position: relative;

  a {
    color: #363636;
  }

  .landing-top {
    padding: 250px 5% 0;
    position: initial;
    z-index: 1;
    top: 9rem;

    &__header {
      color: #ffffff;
      font-family: @PT;
      line-height: 1;

      &::before {
        width: 55px;
        position: absolute;
        top: 5px;
        content: '';
        // border-top: 3px solid #ffffff;
      }

      h1 {
        margin-right: 20px;
        color: #363636;
        font-size: 65px;
        font-weight: bold;
        display: inline;
      }
      span {
        font-size: 35px;
        font-weight: normal;
        color: #363636;
      }
    }

    &__booking {
      width: 80%;
      margin-top: 35px;

      .booking-search-location {
        width: 30%;
        padding: 24px 28px;
        display: flex;
        align-items: center;
        border-radius: 0 5px 5px;
        line-height: 1;
        background: #ffffff;
        font-family: @Lato;
        justify-content: space-between;

        &-box {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-right: 16px;
          border-bottom: 1px solid #1a1a1a;

          .ant-select {
            border-bottom: none;
            width: 100%;
          }

          > svg {
            margin-right: 15px;
            color: #ff0000;
            font-size: 18px;
          }

          > input {
            padding: 0;
            width: 300px;
            height: 24px;
            font-size: 16px;
            border: 0;
            line-height: 1;
            vertical-align: middle;

            &::placeholder {
              color: #000000;
              opacity: 0.5;
              font-size: 16px;
            }
            &:focus {
              border: none;
              outline: none;
            }
          }
        }
      }

      .booking-options {
        button {
          padding: 17px 16px;
          border: none;
          cursor: pointer;
          font-size: 16px;
          font-family: @Lato;
          line-height: 1;

          &:focus {
            outline: none;
          }
          &:first-child {
            border-top-left-radius: 5px;
          }
          &:last-child {
            border-top-right-radius: 5px;
          }
        }

        .text {
          color: #ffffff;
          margin-left: 20px;
          font-family: @Lato;
          font-size: 16px;
        }

        &--active {
          color: #363636;
          background: #ffffff;
        }
        &--inactive {
          color: #ffffff;
          background: #363636;
        }
      }

      .booking-search {
        width: fit-content;
        padding: 24px 28px;
        display: flex;
        align-items: center;
        border-radius: 0 5px 5px;
        line-height: 1;
        background: #ffffff;
        font-family: @Lato;

        // Input Dates
        &__dates {
          padding: 0;
          margin-right: 42px;
          border: none;
          border-radius: 0;
          border-bottom: 1px solid #1a1a1a;
          height: 32px;

          .ant-picker-input {
            input {
              width: 115px;
              // font-size: 16px;

              &::placeholder {
                color: #000000;
                // font-size: 16px;
                opacity: 0.5;
              }
            }

            // Second Date Input
            &:nth-child(3) {
              input {
                text-align: right;
              }
            }
          }

          // Icons
          .ant-picker-range-separator {
            svg {
              font-size: 18px;
            }
          }
          // Calendar Icon
          .ant-picker-suffix {
            margin-left: 12px;
          }
          svg {
            color: #ff0000;
          }

          // Active Sliding Bar
          .ant-picker-active-bar {
            margin-left: 0;
            height: 1px;
            background: #ff0000;
          }
        }

        // Location Input
        &__location {
          margin-right: 16px;
          display: inline-block;
          border-bottom: 1px solid #1a1a1a;
          height: 33px;

          .ant-select {
            border-bottom: none;
          }

          > svg {
            margin-right: 15px;
            color: #ff0000;
            font-size: 18px;
          }

          > input {
            padding: 0;
            width: 300px;
            height: 24px;
            font-size: 16px;
            border: 0;
            line-height: 1;
            vertical-align: middle;

            &::placeholder {
              color: #000000;
              opacity: 0.5;
              font-size: 16px;
            }
            &:focus {
              border: none;
              outline: none;
            }
          }
        }

        // Search button
        &__button {
          cursor: pointer;
          padding: 22px 22px;
          background: #e60000;
          border: none;
          border-radius: 5px;

          > svg {
            color: #ffffff;
            font-size: 18px;
          }
        }
      }
    }
    .ideasLink {
      margin-top: 20px;
      color: #363636;
    }
    // Links underneath booking search bar
    &__links {
      margin-top: 23px;
      padding-bottom: 55px;
      display: flex;

      a {
        color: #363636;
        font-size: 12px;
        letter-spacing: 0.36px;

        &:first-child {
          height: fit-content;

          svg {
            margin-left: 15px;
            transition: margin-left 0.5s ease;
          }
          &:hover {
            > svg {
              margin-left: 25px;
            }
          }
        }

        &:last-child {
          margin-left: auto;
          height: max-content;
          transform: rotate(-180deg);
          writing-mode: vertical-lr;

          svg {
            margin-bottom: 15px;
            transition: margin-bottom 0.5s ease;
          }
          &:hover {
            > svg {
              margin-bottom: 25px;
            }
          }
        }
      }
    }
  }
}

.temporal-home-hero {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: @PT;

  .temporal-link {
    width: 80%;
    margin-top: 24px;
  }

  .temporal-home-box {
    width: 80%;
    h1 {
      font-size: 45px;
      color: #ffffff;
      margin-top: 24px;
      line-height: 100%;
    }

    .temporal-subheading {
      font-size: 35px;
      color: #ffffff;
      margin-top: 24px;
      line-height: 100%;
      margin-bottom: 0px;
    }
  }

  .temporal-button {
    font-size: 18px;
    padding: 12px 32px;
    border: none;
    color: #ffffff;
    margin: 0px 24px;
    &:first-child {
      background-color: #c00202;
      border: 1px solid #c00202;
      &:hover {
        background-color: #f20202;
        border: 1px solid #f20202;
      }
      &:active {
        background-color: #c20000;
      }
    }

    &:last-child {
      background-color: rgba(255, 255, 255, 0.2);
      border: 1px solid #ffffff;
      color: white;
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.5);
      border: 1px solid #ffffff;
    }
    &:active {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
}

.input-searchbar {
  font-size: 16px;
  padding: 10px 16px;
  list-style-type: none;

  &:hover {
    background-color: #f5f6f6;
  }
}

// .input-searchbar:hover {
// }
//***************************//
// ~ Latest Designs ~ Carrousel
//***************************//
.latest {
  &__title-handlers {
    margin: 70px 92px 60px;

    > span:first-child {
      margin-right: 100px;
      color: #363636;
      font-size: 55px;
      font-weight: bold;
      font-family: @PT;
    }

    span {
      button {
        cursor: pointer;
        color: #b7931c;
        font-size: 25px;
        border: none;
        line-height: 0;
        background: none;

        &:first-of-type {
          margin-right: 25px;
        }

        &:focus {
          outline: dashed thin #b7931c;
        }
      }
    }
  }

  &__carousel {
    .carousel-bg {
      width: 600px;
      height: 600px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .slick-list {
      .slick-track {
        display: flex;

        img {
          max-height: 600px;
          height: 100%;
        }

        .slick-slide:hover {
          .hotel-info {
            visibility: visible;
          }
        }
        .slick-slide {
          margin: 0 40px;
          position: relative;
          line-height: 1;
          pointer-events: auto;

          &:hover {
            .hotel-info {
              opacity: 1;
            }
          }

          // Initial Size Slide
          .hotel-info {
            width: 100%;
            padding: 10px 20px;
            display: flex;
            align-items: flex-end;
            font-family: @Lato;
            border-radius: 5px;
            background: #ffffff;
            opacity: 1;
            transition: opacity 0.5s ease;

            span {
              color: #000000;

              p {
                margin: 1rem 0 0 0;
                &:first-child {
                  font-weight: bold;
                }
              }
            }
            a {
              margin-left: auto;
              color: #000000;
              > svg {
                margin-left: 5px;
                vertical-align: bottom;
              }
              .svg-inline--fa.fa-w-14 {
                width: 0.875em;
              }
            }
          }

          // Big Slider
          &--bg {
            .hotel-info {
              width: 75%;
              padding: 37px 40px;
              bottom: 33px;
              left: 33px;
            }
            img {
              width: 600px;
            }
            span {
              font-size: 20px;
              > p {
                font-size: 25px;
              }
            }
            a {
              font-size: 20px;
              > svg {
                margin-left: 15px;
                font-size: 25px;
              }
            }
          }

          // Push down slider
          &--push-down {
            margin-top: 25%;
          }
          // Push up slider
          &--push-up {
            margin-top: -25%;
          }
        }

        .slick-current {
          .hotel-info {
            opacity: 1;
          }
        }
      }
    }
  }
}

.ant-carousel .slick-slider {
  margin: 0 80px;
}

//***************************//
// ~ Collections
//***************************//
.collections {
  margin-top: 100px;
  line-height: 1;

  &__heading {
    display: grid;
    justify-items: center;
    gap: 45px 0;

    span {
      font-size: 20px;
      font-family: @Lato;
      letter-spacing: 3px;
    }
  }

  &__items-container {
    width: fit-content;
    margin: 95px auto 0;
    display: grid;
    justify-items: center;
    gap: 42px 30px;
    grid-template-columns: repeat(2, 1fr);

    .item {
      position: relative;

      span:first-child::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0.37;
        background: #021825;
      }

      img {
        max-width: 500px;
        max-height: 500px;
        width: 100%;
        height: 100%;
      }
      span:nth-of-type(2) {
        a {
          width: fit-content;
          position: absolute;
          left: 0;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #ffffff;
          font-family: @Lato;
          font-size: 25px;
          text-transform: uppercase;
          text-align: center;
        }
      }
    }
  }
}

//***************************//
// ~ Climate Protection
//***************************//
.climate-protection {
  margin: 60px @main-side-space*2+2 0;
  line-height: 1;
  display: grid;
  gap: 60px 0;
  justify-items: center;

  &__separator {
    .square {
      transform: rotate(45deg);
    }
  }
  &__header {
    text-align: center;
    h2 {
      margin-top: 20px;
    }
  }
  &__info {
    width: 90%;
    display: flex;

    > p {
      margin: 0;
      flex: 1 1 0;
      line-height: 1.5;
      font-size: 16px;
      font-family: @Lato;
    }
    .links {
      display: grid;
      flex: 1 1 0;
      gap: 35px 0;
      align-content: center;
      justify-content: center;
      font-size: 16px;
      font-family: @Lato;

      > span {
        svg {
          margin-right: 20px;
          float: left;
          color: #e30000;
        }
        .svg-inline--fa.fa-w-14 {
          width: 0.875em;
        }
        a {
          color: #363636;
        }
      }
    }
  }
}

//***************************//
// ~ Groups & Retreats
//***************************//
.groups-retreats {
  margin: 160px 0 0 @main-side-space;
  align-items: start;
  justify-content: space-between;

  &__info {
    > p {
      margin: 20px 0 0;
      font-size: 16px;
      font-family: @Lato;
    }
    .event-location-form {
      margin-top: 50px;
      display: flex;
      line-height: 1;
      align-items: center;

      &__input-container {
        margin-right: 5%;
        height: fit-content;
        padding-bottom: 8px;
        display: flex;
        flex: 1 1 0;
        border-bottom: 1px solid #1a1a1a;
        vertical-align: middle;

        input {
          padding: 0;
          font-size: 16px;
          font-family: @Lato;
          flex: 1 0;
          border: 0;
          background-color: transparent;

          &::placeholder {
            font-size: 16px;
            opacity: 0.39;
          }
          &:focus {
            outline: none;
          }
        }
        svg {
          font-size: 18px;
          margin-left: 15px;
          color: #ff2800;
        }
      }

      &__button-container {
        a {
          button {
            cursor: pointer;
            width: 62px;
            height: 58px;
            background: #ff2800;
            border: 0;
            border-radius: 5px;

            svg {
              font-size: 18px;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
  &__image-container {
    img {
      width: 100%;
    }
  }
}

//***************************//
// ~ Clients Carousel
//***************************//
.our-clients {
  &__intro {
    margin: 100px @main-side-space 0;
  }
  &__carousel-container {
    margin: 45px 0 0;
    padding-bottom: 35px;

    .slick-list {
      .slick-track {
        display: flex;
        align-items: center;

        .slick-slide {
          margin: 0 45px;

          &:first-of-type {
            margin: 0 45px 0 0;
          }

          .client-container {
            text-align: center;
            img {
              display: initial;
            }
          }
        }
      }
    }
    .slick-dots {
      bottom: 0;
      li {
        margin: 0 10px;
        button {
          background: #ff2800;
        }
      }
      li.slick-active {
        button {
          background: #ff2800;
        }
      }
    }
  }
}

//***************************//
// Blog Hilight Post
//***************************//
.blogImg {
  max-height: 196px;
}
.blog-hightlight {
  margin: 100px @main-side-space 100px;

  .separator-vertical-container {
    text-align: center;
  }

  &__blog-posts {
    line-height: 1;
    .main-post {
      &__info {
        z-index: 1;
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 70px;
        .blog-title {
          display: block;
          width: 64%;
          color: #ffffff;
          font-size: 45px;
          font-family: @PT;
          font-weight: bold;
        }

        p {
          margin: 25px 0 0;
          color: #ffffff;
          font-family: @Lato;
          font-size: 16px;
        }

        .blog-link {
          margin-top: 25px;
          padding: 20px 12px;
          display: inline-block;
          color: #ffffff;
          font-family: @Lato;
          font-size: 25px;
          border: 1px solid #ffffff;
          width: fit-content;
        }
      }
    }
    .posts {
      margin-top: 30px;
      justify-content: space-between;

      > div {
        img {
          width: 100%;
        }
        .info {
          margin: 16px 30px 35px 0;
          h5 {
            a {
              color: #000000;
            }
          }
          p {
            margin: 30px 0 0;
            font-family: @Lato;
            font-size: 16px;
          }

          &__social {
            margin-top: 30px;
            display: flex;
            font-family: @Lato;
            font-size: 14px;
            .likes {
              flex: 1 0;
              svg {
                color: #e30000;
              }
            }
            .comments {
              flex: 1 0;
            }
            .share {
              flex: 2 0;
              text-align: right;
              a {
                color: #363636;
              }
            }
          }
        }
      }
    }
  }
}

//***************************//
// Hotel Family Section
//***************************//
.hotel-family {
  /*   margin-top: 50px;
  padding: 0 100px;
  background: no-repeat left url(../images/rope-bridge.png) #021825;
  background-size: contain; */
  display: flex;
  height: 80vh;

  .hotel-container {
    background-color: #021825;
    width: 50%;
    padding: 0 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__header {
    // padding-top: 80px;
    // text-align: center;
    .title {
      display: block;
      color: #ffffff;
      font-family: @PT;
      font-size: 60px;
      line-height: 1;
      font-weight: bold;
    }
  }

  &__content {
    padding: 35px 0 0;
    display: flex;
    flex-direction: column;

    .info {
      grid-area: body;
      > p {
        color: #ffffff;
        font-family: @Lato;
        font-size: 16px;
      }

      .links {
        margin: 35px 0 0;
        a {
          margin-top: 10px;
          padding: 10px;
          display: inline-block;
          color: #ffffff;
          border: 2px solid #b7931c;
          border-radius: 5px;
          font-family: @Lato;
          font-size: 17px;
        }
        a.fill-link {
          margin-right: 35px;
          padding: 12px;
          color: #363636;
          border: none;
          background: #e3c254;
        }
      }
    }
  }
}

//***************************//
// Media Queries
//***************************//
@media (max-width: 1440px) {
  .homeHero {
    .landing-top {
      &__booking {
        .booking-search-location {
          width: 50%;
        }
      }
    }
  }
}

@media (max-width: 1170px) {
  .latest {
    &__carousel {
      .carousel-bg {
        width: 600px;
        height: 600px;
      }
    }
  }
}

@media (max-width: 1140px) {
  .collections {
    &__items-container {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  // Climate Protection
  .climate-protection {
    margin: 60px @main-side-space 0;

    &__info {
      width: auto;
    }
  }

  // Hotel Family
  .hotel-family {
    background: #021825;

    .hotel-family-bg {
      display: none;
    }

    .hotel-container {
      width: 100%;
    }

    &__content {
      display: block;
    }
  }
}

@media (max-width: 1160px) {
  .homeHero {
    header {
      height: 85vh !important;
    }
  }
}

@media (max-width: 991px) {
  // Groups & Retreats
  .groups-retreats {
    margin: 160px 0;

    &__info {
      padding: 0 @main-side-space;
    }
    &__image-container {
      margin-top: 50px;
    }
  }
}

@media (max-width: 920px) {
  .homeHero {
    header {
      height: 100vh !important;
    }

    .landing-top {
      padding: 180px 5% 0;
      &__booking {
        .booking-search-location {
          width: 100%;
          .booking-search__button {
            width: 20%;
          }
        }
        .booking-options {
          .text {
            display: none;
          }
        }
        .booking-search {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          width: 100%;
          &__dates {
            margin-right: initial;
            width: 100%;
          }
          &__location {
            margin: 35px 0;
            width: 100%;
          }
          &__button {
            width: 100%;
          }
        }
      }
    }
  }

  // Carousel
  .latest {
    &__title-handlers {
      margin: 70px @main-side-space 30px;

      > span:first-child {
        font-size: 40px;
      }
      span {
        button {
          font-size: 35px;
        }
      }
    }
  }
  .ant-carousel .slick-slider {
    margin: 0 15px;
  }

  .latest__carousel .slick-list .slick-track .slick-slide {
    margin: 0 10px;
  }
}

@media (max-width: 772px) {
  .homeHero {
    .landing-top {
      &__header {
        h1 {
          font-size: 55px;
        }
        span {
          font-size: 35px;
        }
      }

      &__booking {
        .booking-search {
          width: 100%;
          display: grid;
          justify-items: center;

          &__dates {
            margin-right: initial;
            width: 100%;
          }

          &__location {
            margin: 35px 0;
            width: 100%;
          }
          &__button {
            width: 100%;
          }
        }
      }
    }
  }

  // Carousel
  .latest {
    &__title-handlers {
      display: grid;
      justify-items: center;

      > span:first-child {
        margin: 0 0 20px;
      }
    }

    &__carousel {
      .slick-list {
        .slick-track {
          .slick-slide {
            .hotel-info {
              padding: 0;
              position: relative;
              width: 85%;
              bottom: 0;
              left: 0;
              margin: 0;

              span {
                width: 170px;
                p {
                  margin-top: 8px;
                }
              }
            }

            // Big Slider
            &--bg {
              .hotel-info {
                width: 85%;
                padding: 25px 28px;
                bottom: 25px;
                left: 25px;

                span {
                  font-size: initial;
                  > h3 {
                    font-size: initial;
                  }
                }
                a {
                  font-size: initial;
                  > svg {
                    margin-left: initial;
                    font-size: initial;
                  }
                }
              }
              img {
                width: 370px;
                height: 562px;
                object-fit: cover;
              }
              span {
                font-size: 20px;
                > p {
                  font-size: 25px;
                }
              }
              a {
                font-size: 20px;
                > svg {
                  margin-left: 15px;
                  font-size: 25px;
                }
              }
            }
            // Push down slider
            &--push-down {
              margin-top: initial;
            }
            // Push up slider
            &--push-up {
              margin-top: initial;
            }
          }
        }
      }
    }
  }

  .ant-carousel .slick-slider {
    margin: 0 15px;
  }

  .latest__carousel {
    .slick-list {
      .slick-track {
        img {
          // height: 80%;
          height: auto;
          max-width: 100%;
        }
      }
    }
  }

  // Collections
  .collections {
    &__items-container {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  // Climate Protection
  .climate-protection {
    &__header {
      h4 {
        margin-right: auto;
      }
    }

    &__info {
      display: grid;
      gap: 50px 0;

      .links {
        gap: 50px 0;
      }
    }
  }

  // Clients
  .our-clients {
    &__intro {
      margin: 100px @main-side-space 0;
    }
    &__carousel-container {
      .slick-list {
        .slick-track {
          .slick-slide {
            margin: 0;

            &:first-of-type {
              margin: 0;
            }

            .client-container {
              text-align: center;
              img {
                display: initial;
              }
            }
          }
        }
      }
    }
  }

  // Blog Posts Highlight
  .blog-hightlight {
    &__blog-posts {
      .main-post {
        &__info {
          padding: 35px;
          h3 {
            width: 100%;
          }
          a {
            font-size: 15px;
          }
        }
      }
      .posts {
        > div {
          .info {
            margin: 35px 0px;
          }
        }
      }
    }
  }

  // Hotel Family
  .hotel-family {
    .hotel-container {
      .hotel-family__header {
        padding-top: 40px;
        .title {
          font-size: 50px;
        }
      }

      .hotel-family__content {
        padding: 45px 0;
        .info {
          .links {
            a {
              display: block;
            }
            a.fill-link {
              margin: 0 0 30px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 682px) {
  .latest {
    &__carousel {
      .carousel-bg {
        width: 400px;
        height: 400px;
      }
    }
  }
  .temporal-home-hero {
    .temporal-home-box {
      h1 {
        font-size: 48px;
      }
      .temporal-subheading {
        font-size: 32px;
      }
    }
  }
}

@media (max-width: 558px) {
  .booking-search-drop-down {
    .ant-picker-panels {
      display: grid;
    }
  }
  .homeHero {
    .landing-top {
      top: 3rem;
      padding: 140px 5% 0;

      &__booking {
        width: 100%;
        .booking-search-location {
          display: flex;
          flex-direction: column;
          .booking-search__button {
            width: 100%;
            margin-top: 35px;
          }
        }
      }
      &__header {
        h1 {
          font-size: 35px;
          br {
            display: none;
          }
        }
        span {
          font-size: 24px;
        }
      }
    }
  }

  .temporal-home-hero {
    h1 {
      font-size: 36px;
    }
    .temporal-home-box {
      .temporal-subheading {
        font-size: 24px;
      }
    }
    .temporal-button-container {
      display: flex;
      flex-direction: column;

      .temporal-button {
        margin: 12px 0;
      }
    }
  }

  .collections {
    &__items-container {
      .item {
        img {
          max-width: 300px;
        }
      }
    }
  }

  .hotel-family {
    background: #021825;

    .hotel-family-bg {
      display: none;
    }

    .hotel-container {
      padding: 0 50px;
      height: 100%;
      .hotel-family__header {
        .title {
          font-size: 45px;
        }
      }
    }
  }
}

@media (max-width: 410px) {
  .latest {
    &__carousel {
      .carousel-bg {
        width: 300px;
        height: 300px;
      }
    }
  }

  .homeHero {
    .landing-top {
      top: 1rem;
      padding: 140px 5% 0;

      &__booking {
        .booking-search {
          display: flex;
          flex-direction: column;

          &__dates {
            display: flex;
            flex-direction: column;
            margin-right: initial;
            width: 100%;
            border: none;
          }

          &__location {
            margin: 0;
            margin-bottom: 2rem;
            width: 100%;
          }
          &__button {
            width: 100%;
          }
        }
      }
    }
  }

  .homeHero
    .landing-top__booking
    .booking-search__dates
    .ant-picker-input:nth-child(3)
    input {
    text-align: initial;
    // border-top: 1px solid #1a1a1a;
  }
  .homeHero
    .landing-top__booking
    .booking-search__dates
    .ant-picker-range-separator
    svg {
    display: none;
  }
  .homeHero .landing-top__booking .booking-search__dates .ant-picker-suffix {
    span {
      display: none;
    }
  }
  .homeHero
    .landing-top__booking
    .booking-search__dates
    .ant-picker-input
    input {
    border-bottom: 1px solid #1a1a1a;
    margin-bottom: 2rem;
  }
  .homeHero .landing-top__booking .booking-search__location > input {
    width: auto;
    margin-bottom: 1px;
  }
  .blog-hightlight__blog-posts .main-post__info .blog-title {
    font-size: 32px;
  }
  .blog-hightlight__blog-posts .posts > div .info {
    margin: 25px 0px;
  }
}


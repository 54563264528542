@import './App.less';

// Black Links & Logo
.not-found {
  .navigation-bar {
    &__logo {
      svg {
        path {
          fill: #ffffff;
        }
      }
      > span {
        color: #ffffff;
      }
      img {
        width: 160px;
      }
    }
    nav {
      > span a {
        color: #ffffff;
      }
      .menu-icon {
        color: #ffffff;
      }
    }
  }
}

.booking-search-location {
  width: 25%;
  padding: 24px 28px;
  display: flex;
  align-items: center;
  border-radius: 0 5px 5px;
  line-height: 1;
  background: #ffffff;
  font-family: @Lato;
  justify-content: space-between;

  &-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-right: 16px;
    border-bottom: 1px solid #1a1a1a;

    .ant-select {
      border-bottom: none;
      width: 100%;
    }

    > svg {
      margin-right: 15px;
      color: #ff0000;
      font-size: 18px;
    }

    > input {
      padding: 0;
      width: 300px;
      height: 24px;
      font-size: 16px;
      border: 0;
      line-height: 1;
      vertical-align: middle;

      &::placeholder {
        color: #000000;
        opacity: 0.5;
        font-size: 16px;
      }
      &:focus {
        border: none;
        outline: none;
      }
    }
  }
  .booking-search-button {
    cursor: pointer;
    padding: 12px 12px;
    background: #e60000;
    border: none;
    border-radius: 5px;

    > svg {
      color: #ffffff;
      font-size: 18px;
    }
  }
}

// White Links & Logo
.navigation-bar {
  width: 100%;
  padding: 25px @main-side-space;
  position: fixed;
  background: #0000007a;
  z-index: 999;
  display: flex;
  align-items: center;

  &__logo {
    line-height: normal;

    > svg {
      width: 17.78px;
      height: 22.1px;
      margin-right: 20px;
    }

    > span {
      color: #ffffff;
      font-family: @EuphemiaBold;
      font-size: 28px;
      letter-spacing: -0.84px;
    }
    img {
      width: 160px;
    }
  }

  nav {
    line-height: normal;
    margin-left: auto;

    > span {
      margin-left: 30px;
      &:first-of-type {
        margin-left: 0;
      }
    }
    > span a {
      color: #ffffff;
      font-size: 10px;
      font-family: @Lato;
      font-style: normal;
      font-weight: bold;
      letter-spacing: 0.31px;
      text-transform: uppercase;
    }
    .social {
      a {
        font-size: 15px;
      }
    }
  }
  .menu-icon {
    color: #ffffff;
    cursor: pointer;
    font-size: 16px;
    vertical-align: middle;
  }
}
// side Nav
.navigation-bar__drawer .ant-drawer-content-wrapper {
  min-width: 100%;
  .ant-drawer-content {
    .ant-drawer-wrapper-body {
      // Closeing Button
      .ant-drawer-close {
        top: 50px;
        right: 50px;

        .anticon-close {
          font-size: 45px;
          svg {
            color: #ffffff;
          }
        }
      }
    }
    .ant-drawer-body {
      margin-top: 50px;
      color: #ffffff;

      .lux-navigation-content {
        &__logo {
          font-family: @EuphemiaBold;
          font-size: 35px;
          text-align: center;
          svg {
            margin-right: 25px;
            width: 22.68px;
            height: 28.2px;
          }
          a {
            color: #ffffff;
          }
          img {
            width: 260px;
          }
        }
        .separator-w-square {
          margin-top: 40px;
          .separator-vertical {
            border-color: #ffffff;
          }
          .square {
            margin: 15px auto;
          }
        }
        &__main-links {
          margin: 80px @main-side-space;
          display: flex;
          justify-content: space-around;
          > div {
            display: grid;
            // justify-items: center;
            a {
              color: #ffffff;
              font-family: @Lato;
              font-size: 25px;
              font-weight: bold;
              letter-spacing: 1px;
              text-transform: uppercase;
              // text-align: center;

              &:first-child {
                margin-bottom: 70px;
              }
            }
          }
        }
        &__social-links {
          margin-top: 70px;
          text-align: center;

          a {
            color: #ffffff;
            font-size: 27px;
            &:not(:first-child) {
              margin-left: 50px;
            }
            svg {
              width: 1em;
            }
          }
        }
      }
    }
  }
}

// Media Queries
@media (max-width: 1200px) {
  .booking-search-location {
    width: 40%;
  }
}

@media (max-width: 900px) {
  .booking-search-location {
    width: 60%;
  }
}

@media (max-width: 800px) {
  .navigation-bar {
    &__logo {
      > span {
        display: none;
      }
    }
  }
}

@media (max-width: 740px) {
  .navigation-bar__drawer .ant-drawer-content-wrapper {
    .ant-drawer-content {
      .ant-drawer-wrapper-body {
        // Closeing Button
        .ant-drawer-close {
          top: 10px;
          right: 10px;

          .anticon-close {
            font-size: 35px;
          }
        }
      }
      .ant-drawer-body {
        .lux-navigation-content {
          .separator-w-square {
            margin-top: 40px;
          }
          &__main-links {
            margin: 50px @main-side-space;
            display: grid;
            justify-content: center;
            text-align: center;
            gap: 90px 0;
          }
        }
      }
    }
  }
}

@media (max-width: 566px) {
  .booking-search-location {
    width: 100%;
    order: 3;
    padding: 0px 12px;
  }

  .navigation-bar {
    display: flex;
    justify-content: space-between;
    .navigation-links {
      display: none;
    }
  }
}

@media (max-width: 440px) {
  .navigation-bar {
    nav {
      span {
        margin-left: 15px;
      }

      .svg-inline--fa.fa-w-14 {
        width: 0.875rem;
      }
    }
  }
}


// Fonts
@font-face {
  font-family: 'Euphemia-Bold';
  src: local('Euphemia-Bold'),
    url(../fonts/Euphemia-UCAS-Bold.ttf) format('truetype');
}
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
@PT: 'PT Sans', sans-serif;
@Lato: 'Lato', sans-serif;
@EuphemiaBold: Euphemia-Bold, sans-serif;
@Frank: 'Frank Ruhl Libre', serif;

// Spaces
@main-side-space: 5%;

// Separators
.separator-vertical {
  margin: 0;
  height: 75px;
  opacity: 0.42;
  top: 0;
  border-color: #363636;
}
.square {
  margin: 0 auto;
  width: 9px;
  height: 9px;
  display: block;
  opacity: 0.46;
  background: #b7931c;
  transform: rotate(45deg);

  &--big {
    width: 37px;
    height: 37px;
    border: 2px solid #b7931c;
    background: none;
    position: absolute;
  }
}
.separator-w-square {
  text-align: center;
  .separator-vertical {
    margin: 0;
    top: -6px;
  }
}

// Headers
h1,
h2,
h3,
h4,
h5,
h6
.headline {
  margin: 0;
}
h1{
  font-size: 65px;
  font-family: @PT;
  font-weight: bold;
  margin-bottom: 0;
}
h2 {
  font-size: 55px;
  font-family: @PT;
  font-weight: bold;
  margin-bottom: 0;
}
h3 {
  font-family: @Frank;
  font-size: 45px;
}
h4 {
  font-family: @Lato;
  font-size: 20px;
  letter-spacing: 3px;
  text-transform: uppercase;
}
h5 {
  font-family: @PT;
  font-size: 20px;
}
h6 {
  font-family: @PT;
  font-size: 25px;
  font-weight: bold;
}
.headline{
    font-size: 8rem;
  font-family: @PT;
  font-weight: bold;
  margin-bottom: 0;
}
.headlineSmall{
    font-size: 4rem;
  font-family: @PT;
  font-weight: bold;
  margin-bottom: 0;
  margin-right: 190px;
}

// Lists
ul {
  margin: 0;
  padding: 0;
}

//***************************//
// General Sections
//***************************//
.subscribe {
  margin: 150px @main-side-space;
  display: grid;
  justify-content: center;

  h2 {
    color: #363636;
  }
  .subscribe__field-area {
    margin-top: 35px;
    display: flex;

    input {
      margin-right: 25px;
      flex: auto;
      font-family: @Lato;
      font-size: 18px;
      border: none;
      border-bottom: 1px solid #1a1a1a;

      &::placeholder {
        font-size: 18px;
        color: #000000;
        opacity: 0.39;
      }
    }

    .icon-link {
      display: inline-block;
      padding: 15px 18px;
      text-align: center;
      color: white;
      background: #ff2800;
    }
  }
  .iframeResponsive {
    height: 0;
    overflow: hidden;
    padding-bottom: 46.25%;
    padding-top: 30px;
    position: relative;
  }
  .iframeResponsive iframe,
  .iframeResponsive object,
  .iframeResponsive embed {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    top: -10%;
  }
}

.links-block {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  line-height: 1;
  width: auto;

  > span {
    padding: 30px;
    border: 1px solid #707070;
    &:first-of-type {
      border-bottom: none;
    }
    &:last-of-type {
      border-top: none;
    }
    &:hover {
      box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
        0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
    }

    a {
      color: #363636;
      font-family: @Lato;
      font-size: 25px;

      span {
        vertical-align: middle;
      }
      svg {
        float: right;
      }
    }
    svg {
      font-size: 30px;
    }
  }
}
.floatsies{
  float:right;
}
.red{
  color: #ff2800;
}
// 404 Not Found
.not-found {
  .separator-w-square {
    padding-top: 50px;
    .separator-vertical {
      height: 175px;
    }
    .square {
      width: 12.87px;
      height: 12.87px;
    }
  }
  &__main-content {
    text-align: center;

    .title {
      color: #4b4b4b;
      font-family: @PT;
      font-size: 145px;
      font-weight: bold;
    }
    p {
      color: #ff2800;
      font-family: @PT;
      font-size: 20px;
      font-weight: bold;
    }
  }
}

.ant-picker-focused {
  -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

// Popup
.modal-btn {
  position: fixed;
  bottom: 3%;
  right: 3%;
  z-index: 25;
  padding: 15px;
  height: auto;
  border: none;
  border-radius: 25px;
  background: #e30000;
  line-height: 0;

  &:hover,
  &:focus {
    background: #b7931c;
  }

  svg {
    width: 17.78px;
    height: 22.1px;
  }
}
.popup {
  img {
    width: 100%;
  }
  top: 30%;
  margin-right: 50px;
  .ant-modal-body {
    padding: 0;
    .content {
      padding: 20px;

      .header {
        display: block;
        height: 100%;
        h4 {
          font-weight: bold;
          letter-spacing: normal;
          text-transform: none;
        }
        .social {
          float: right;
          a {
            color: #b7931c;
            font-size: 20px;
            &:first-child {
              // margin-right: 10px;
            }
          }
        }
      }
      .body {
        font-size: 14px;
        font-family: @Lato;
        > p {
          margin-top: 20px;
        }
        > div {
          margin-top: 20px;
          color: #4b4b4b;
          a{
            color: #4b4b4b;
          }
          svg {
            margin-right: 15px;
            color: #e30000;
            font-size: 20px;
          }
        }
      }
    }
  }
  .ant-modal-close {
    .ant-modal-close-x {
      .ant-modal-close-icon {
        svg {
          fill: white;
          font-size: 23px;
        }
      }
    }
  }
}

// Spinner

.ant-spin {
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  position: relative;
  margin-top: 3rem;

  .ant-spin-dot {
    position: absolute;
    top: -45px;
    margin-top: 3rem;
  }

  .ant-spin-dot-item {
    background-color: #b7931c;
  }
  .ant-spin-text {
    font-size: 20px;
    margin-top: 3rem;
  }
}
@media (max-width: 772px) {
  .subscribe {
    margin: 50px @main-side-space;
  }
}

@media (max-width: 558px) {
  h2 {
    font-size: 43px;
  }
  h3 {
    font-size: 33px;
  }
}

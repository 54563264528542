@import './App.less';

footer{
  padding: 30px @main-side-space 25px;
  background-color: #363636;

  .main{
    display: flex;
    margin-bottom: 50px;
    line-height: initial;
    align-items: flex-start;

    .brand-area{
      margin-right: auto;

      &__logo{
        margin-bottom: 13px;
        
        > svg{
          width: 17.78px;
          height: 22.1px;
          margin-right: 20px;
        }
        > span{
          color:#ffffff;
          font-family: @EuphemiaBold;
          font-size: 28px;
          line-height: initial;
          letter-spacing: -0.84px;
        }
        img{
          width: 160px;
        }
      }

      &__terms-privacy{
        margin-bottom: 32px;
        
        span, a{
          color: #ffffff;
          font-size: 12px;
        }
        span:nth-child(2){
          margin: 0 3px;
        }
      }

      &__parterns-button{
        width: fit-content;
        padding: 10px 12px;
        color: #ffffff;
        display: flex;
        font-size: 12px;
        align-items: center;
        border-radius: 5px;
        background: #E30000;

        > svg{
          margin-left: 10px;
          font-size: 13px;
          transition: margin-left 0.5s ease;
        }

        &:hover{
          > svg{
            margin-left: 18px;
          }
        }
      }
    }

    .contact-site-map{
      display: flex;
      align-items: flex-start;
      font-size: 14px;
      font-family: @Lato;
      
      > div{
        display: grid;
        gap: 13px 0; 
        grid-gap: 13px 0; // Old prefix used by older broswer versions
        margin-left: 72px;

        &:first-child{
          margin-left: 0;
          margin-right: 114px;
        }
        &:nth-child(2){
          margin-left: 0;
        }
        
        > a, > span{
          color: #ffffff;
        }
      }
    }
  }
  
  // Final Section in the Footer
  .bottom{
    display: flex;
    color: #ffffff;
    line-height: normal;
    font-family: @Lato;

    &__social-links{
      margin-left: auto;

      > a{
        margin-left: 30px;
        color: #ffffff;

        &:first-child{
          margin-left: 0;
        }

        svg{
          width: 1em!important;
        }
      }
    }
  }
}

// Media Queries
@media (max-width: 800px){ 
  footer{
    .main{

      .contact-site-map{
        > div{
          gap: 13px 0; 
          grid-gap: 13px 0; // Old prefix used by older broswer versions
          margin-left: 50px;

          &:first-child{
            margin-right: initial;
          }
          &:nth-child(2){
            margin-left: 50px;
          }
          
          > a, > span{
            color: #ffffff;
          }
        }
      }
    }
  }
}

@media (max-width:700px){ 
  footer{

    .main{
      display: grid;
      
      .brand-area{
        margin: 0 auto;
        text-align: center;

        &__terms-privacy{
          margin-bottom: 25px;
        }

        &__parterns-button{
          margin: 0 auto 20px;
        }
      }

      .contact-site-map{
        display: grid;
        
        > div{
          margin: 15px auto 12px;
          gap: 15px 0; 
          grid-gap: 15px 0; // Old prefix used by older broswer versions
          text-align: center;
          

          &:first-child{
            display: grid;
            justify-content: space-evenly;
          }
          &:nth-child(2){
            margin: 15px auto 12px;
          }
          
          > a, > span{
            color: #ffffff;
          }
        }
      }
    }
  }
}

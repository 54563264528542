@import './App.less';

.hotel-cover {
  &__info {
    padding: 16% 7% 0;
    color: #ffffff;
    line-height: 1;
    position: relative;

    .title {
      // width: min-content;
      display: block;
      font-family: @PT;
      font-size: 110px;
      font-weight: bold;
      line-height: 0.9;
      overflow-wrap: break-word;
    }

    .title-white {
      color: #ffff;
    }

    .main-details {
      margin: 30px 0 0;

      p {
        font-family: @Lato;
        font-size: 25px;
        text-transform: capitalize;
      }
    }
  }

  &__reserve {
    width: fit-content;
    margin: 80px @main-side-space 0;
    padding: 35px 40px;
    background: rgba(255, 255, 255, 0.95);

    a {
      padding-right: 80px;
      display: flex;
      align-items: center;
      color: #363636;
      font-family: @Lato;
      font-size: 16px;
      letter-spacing: 2.4px;
      text-transform: uppercase;

      svg {
        margin-left: 15px;
      }
    }
  }
}

.reserve-buttons {
  display: flex;
  margin-left: 7%;

  .reserve-container {
    padding: 35px 80px;
    border: 0px;
    background: rgba(255, 255, 255, 0.95);

    a {
      font-family: @Lato;
      color: #363636;
      text-transform: uppercase;
      letter-spacing: 2.4px;
      font-size: 16px;
    }
  }

  .reserve-container:hover {
    background-color: #000;
    a {
      color: #ffffff;
    }
  }
  .reserve-container-transparent {
    background: rgba(255, 255, 255, 0.4);
    padding: 35px 80px;
    border: 0px;

    a {
      font-family: @Lato;
      color: #ffffff;
      text-transform: uppercase;
      letter-spacing: 2.4px;
      font-size: 16px;
    }
  }
  .reserve-container-transparent:hover {
    background-color: #000;
    a {
      color: #dccc98;
    }
  }
}

//***************************//
// Hotel Details
//***************************//
.hotel-details {
  margin: 0 @main-side-space 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));

  &__text-block {
    z-index: 1;
    grid-area: ~'1 / 1 / auto / span 2';

    .block-container {
      padding: 50px 30px 25px;
      background: rgba(255, 255, 255, 0.95);

      p {
        margin: 20px 0 0;
        font-family: @Lato;
        font-size: 16px;
      }

      .links {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        line-height: 1;

        .gallery-link {
          font-family: @Lato;
          font-size: 20px;

          svg {
            margin-right: 20px;
          }
          a {
            color: #363636;
            text-transform: uppercase;
          }
        }

        .categories {
          span {
            padding: 8px 10px;
            background: #021825b8;
            border-radius: 5px;
            color: #ffffff;
            font-family: @Lato;
            font-size: 14px;
            text-transform: capitalize;
          }
        }
      }
      .gallery-container {
        margin-top: 40px;
        > div {
          display: flex;
          flex-wrap: wrap;

          img {
            cursor: pointer;
            padding: 10px;
            max-width: 100px;
            height: 80px;
            object-fit: cover;
          }
        }
      }
    }
  }
  &__cover {
    grid-area: ~'1 / 2 / auto / span 2';
    img {
      width: 100%;
    }
  }
}

.experiences {
  margin: 90px @main-side-space 0;
  // display: grid;
  // grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
  // align-items: flex-start;
  // justify-items: center;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 7rem;
  margin-bottom: 7rem;
  &__title {
    span {
      display: block;
      width: min-content;
      color: #363636;
      font-family: @PT;
      font-size: 70px;
      font-weight: bold;
      line-height: 1;
    }
  }
  &__links {
    margin-top: 0px;
    width: 30vw;
  }
}

//***************************//
// Ready to Stay ~ Directions
//***************************//
.ready-to-stay {
  margin-top: 80px;
  position: relative;

  &__map {
    .mapboxgl-map {
      height: 450px;
      width: 100%;
    }
  }
  &__directions {
    width: 850px;
    padding: 40px;
    position: absolute;
    top: 25%;
    right: 0;
    z-index: 2;
    background: rgba(255, 255, 255, 0.95);

    .name {
      font-family: @Lato;
      font-size: 14px;
    }
    p {
      font-family: @Lato;
      font-size: 16px;
    }
  }
  &__request-rfp {
    div {
      width: 80%;

      .title {
        display: block;
        color: #ffffff;
        font-family: @PT;
        font-size: 120px;
        line-height: 1;
        font-weight: bold;
      }
      p {
        margin-top: 45px;
        color: #ffffff;
        font-family: @Lato;
        font-size: 16px;
      }
      button {
        margin-top: 20px;
        padding: 10px 15px;
        border: none;
        border-radius: 5px;
        background: #e3c254;
        font-family: @Lato;
        font-size: 17px;

        a,
        svg {
          color: #363636;
        }

        svg {
          margin-left: 10px;
          vertical-align: middle;
        }
      }
    }
  }
}

//***************************//
// Media Queries
//***************************//
@media (max-width: 1081px) {
  .hotel-details {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    gap: 30px 0;

    &__text-block {
      grid-area: auto;
    }
    &__cover {
      grid-area: auto;
    }
  }

  .reserve-buttons {
    margin-left: 0;
    justify-content: center;
  }
}
@media (max-width: 920px) {
  .hotel-cover {
    &__info {
      padding: 80px @main-side-space 0;

      .title {
        font-size: 100px;
      }
    }
  }
  // Ready to Stay ~ Directions
  .ready-to-stay {
    &__map {
      height: 370px;
    }
    &__directions {
      width: auto;
      position: relative;
      background: rgba(255, 255, 255, 1);
    }
  }
}

@media (max-width: 785px) {
  .reserve-buttons {
    .reserve-container {
      padding: 25px 30px;
    }
    .reserve-container-transparent {
      padding: 25px 30px;
    }
  }
}

@media (max-width: 859px) {
  .experiences {
    // margin: 80px @main-side-space 0;
    // gap: 40px 0;
    /*   &__title {
      text-align: center;
      justify-self: center;
    } */
  }
}

@media (max-width: 772px) {
  .ready-to-stay {
    &__request-rfp {
      div {
        width: 100%;
        .title {
          font-size: 80px;
        }
      }
    }
  }
}
@media (max-width: 584px) {
  .reserve-buttons {
    flex-wrap: wrap;
    .reserve-container {
      padding: 20px 20px;
      width: 100%;

      .square {
        width: 0;
        height: 0;
        border: none;
        position: initial;
      }
      .square--big {
        width: 0;
        height: 0;
        border: none;
        position: initial;
      }
    }
    .reserve-container-transparent {
      padding: 20px 20px;
      width: 100%;
      background-color: #000;
    }
    .reserve-container-transparent:hover {
      background-color: #ffffff;
      color: #000000;
    }
  }
}
@media (max-width: 558px) {
  .hotel-cover {
    &__info {
      padding: 50% @main-side-space 0;

      .title {
        font-size: 50px;
      }
    }

    &__reserve {
      margin: 40px 5% 0;
      padding: 28px 30px;
      a {
        padding-right: 0;
        font-size: 12px;
      }
    }
  }
  .hotel-details {
    &__text-block {
      .block-container {
        padding: 50px 0 25px;
        .links {
          display: inline-block;
          line-height: unset;
          .categories {
            margin-top: 15px;
          }
        }
      }
    }
  }
  .experiences {
    /*    // grid-template-columns: auto;
    &__title {
      span {
        font-size: 40px;
      }
    } */
  }

  // Ready to Stay ~ Directions
  .ready-to-stay {
    &__request-rfp {
      div {
        .title {
          font-size: 42px;
        }
      }
    }
  }
}


.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: none;
}
.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0;
}
.ant-tabs-tab.ant-tabs-tab-active {
  //   border-bottom-color: unset;
  border-bottom: none;
  background-color: #fff;
  color: #000;
}
.ant-tabs-tab.ant-tabs-tab-active:hover {
  color: #e60000;
}
.ant-tabs-tab:hover {
  color: #e60000;
}

.ant-tabs-tab {
  //   border-bottom-color: unset;
  border-bottom: none;
  background-color: #363636;
  padding: 15px 20px;
  color: #fff;
  margin: 0;
}
.ant-tabs-ink-bar {
  background: none;
}
.groups-hero {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 70vh;

  .groups-hero-box {
    width: 80%;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    .groups-hero-heading {
      line-height: 66px;
      color: #fff;
    }

    .groups-hero-subtitle {
      font-size: 25px;
      font-weight: 600;
      color: #fff;
      margin-bottom: 16px;
    }
  }
}

.groups-form-container {
  display: flex;
  justify-content: space-evenly;
  padding-top: 60px;
  padding-bottom: 100px;
  color: #363636;
}
.groups-box-info {
  display: flex;
  flex-direction: column;
  width: 30%;
  background-color: #fff;
  position: relative;

  .groups-box-link {
    font-size: 20px;
    color: #363636;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-top: 48px;
  }
}

.groups-info {
  // margin-top: 60px;
}
.groups-box-form {
  margin-top: 60px;

  // bottom: 100px;
  // padding: 40px;
}
.groups-form {
  display: flex;
  flex-direction: column;
  position: relative;

  input {
    border: none;
    border-bottom: 1px solid #000;
    margin: 5px;
    padding: 10px 5px;
  }
  .groups-form-sublevel {
    display: flex;
    justify-content: space-between;

    input {
      width: 45%;
    }
  }
  .groups-form-button {
    background-color: red;
    color: #fff;
    padding: 15px 5px;
    margin-top: 35px;
    border: none;
  }
}
.groups-images {
  display: flex;
  justify-content: space-evenly;
  // flex-direction: column;
  // align-items: flex-end;
}
.groups-images-container {
  width: 35%;
  margin-left: 100px;
  padding-top: 50px;
}
.groups-images-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  width: 40%;
  align-items: center;
}
.groups-images-line {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  top: -100px;
  width: 30%;

  .separator-w-square-about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: -7rem;
    margin-top: 2rem;
  }
  .separator-vertical-about-hero {
    width: 0;
    height: 75vh;
    opacity: 0.42;

    border-color: #363636;
  }
  .squareAbout {
    width: 9px;
    height: 9px;
    opacity: 0.46;
    background: #b7931c;
    transform: rotate(45deg);
    margin-top: 20px;
  }
}
.groups-difference {
  display: flex;
  flex-direction: column;

  .servicesSeparator {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 25vh;
    width: 50%;
    .separator-vertical-services {
      height: 15vh;
    }
  }
}
.groups-difference-container {
  width: auto;
  margin-left: 13%;
}
.groups-difference-box {
  display: flex;
  flex-direction: column;
  padding: 100px 120px;
  border: 1px solid #000;
  text-align: center;
  margin-bottom: 200px;
  width: 50%;
}
.groups-dates {
  .ant-picker-input {
    display: flex;
    flex-direction: row-reverse;

    .ant-picker-suffix {
      margin-left: 0 !important;
      margin-right: 12px;
    }
  }
}
.groups-selector {
  margin-right: 20px;
  border-bottom: 1px solid #000;
  // height: 35px;

  .svg-inline--fa.fa-w-18 {
    color: #ff0000;
    margin-right: 10px;
    margin-bottom: 5px;
  }

  .groups-selector-event {
    width: 180px;

    .ant-select-selector {
      height: 25px;
    }
  }
}
.groups-selector div {
  // font-size: 16px;

  border: none;
}
.input-autocomplete {
  border: none;
}
.groups-location {
  display: flex !important;
  align-items: center;
  width: 200px;
}

.ant-select-selection-placeholder {
  opacity: unset;
  color: rgba(0, 0, 0, 0.45);
}

.ant-select {
  border-bottom: 1px solid #000;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
}

.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 25px !important;
}

.groups-hero-bg {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.groups-hero-title > * {
  color: #fff;
  text-align: center;
}

@media (max-width: 1440px) {
  .groups-box-info {
    width: 40%;
  }
}

@media (max-width: 1175px) {
  .groups-images-line {
    .separator-w-square-about {
      display: none;
    }
  }
  .groups-difference {
    align-items: center;

    .servicesSeparator {
      width: 100%;
    }
  }
  .groups-difference-container {
    margin-left: 0;
    width: 80%;
  }

  .groups-difference-box {
    width: 100%;
  }
}

@media (max-width: 950px) {
  .groups-selector {
    .groups-selector-event {
      width: auto;
    }
  }
}

@media (max-width: 920px) {
  .groups-selector > div {
    width: 80%;
  }

  .groups-selector {
    width: 100%;
    margin-right: 0;
    .groups-selector-event {
      width: 80%;
    }
  }
  .groups-button {
    margin-top: 35px;
  }
  .groups-hero-title {
    width: 80%;
  }
}

@media (max-width: 786px) {
  .groups-hero {
    .groups-hero-box {
      .groups-hero-heading {
        font-size: 48px;
      }
      .groups-hero-subtitle {
        font-size: 20px;
      }
    }
  }

  .groups-form-container {
    flex-wrap: wrap;
  }
  .groups-box-info {
    width: 80%;
  }
  .groups-box-form {
    padding: 0;
    bottom: 0;
    margin-top: 50px;
  }
  .groups-images-line {
    display: none;
  }
  .groups-images-container {
    margin-left: 0;
    width: 80%;
  }
  .groups-images {
    justify-content: center;
  }
  .groups-difference-box {
    padding: 60px 80px;
  }
}

@media (max-width: 670px) {
  .groups-images-grid {
    grid-template-columns: repeat(2, 1fr);
    width: auto;
    img {
      justify-self: flex-start !important;
      max-width: 180px;
    }
  }
}

@media (max-width: 625px) {
  .groups-difference-box {
    padding: 40px 60px;
  }
}

@media (max-width: 571px) {
  .groups-difference-box {
    padding: 30px 50px;
    p {
      text-align: initial;
    }
  }
}

@media (max-width: 480px) {
  .groups-hero-title {
    font-size: 48px;
  }
  .groups-images-grid {
    grid-template-columns: repeat(2, 1fr);
    width: auto;
    img {
      justify-self: flex-start !important;
      max-width: 150px;
    }
  }
}
@media (max-width: 424px) {
  .groups-hero {
    .groups-hero-box {
      .groups-hero-heading {
        font-size: 42px;
      }
      .groups-hero-subtitle {
        font-size: 18px;
      }
    }
  }

  .groups-input {
    width: auto !important;
  }
  .groups-difference-box {
    padding: 20px 15px;
    margin-bottom: 80px;
  }
}
@media (max-width: 410px) {
  .homeHero
    .landing-top__booking
    .booking-search__dates
    .ant-picker-suffix
    span {
    display: inline;
  }
  .homeHero
    .landing-top__booking
    .booking-search__dates
    .ant-picker-input
    input {
    border-bottom: none;
  }
  .homeHero .landing-top__booking .booking-search__dates {
    border-bottom: 1px solid #000;
  }
}
@media (max-width: 411px) {
  .groups-location {
    margin: 35px 0 !important;
  }
  .groups-dates input {
    margin-bottom: 0 !important;
  }
  .groups-selector div {
    font-size: 16px;

    padding: 0 !important;
  }

  .groups-images-grid {
    img {
      max-width: 120px;
    }
  }
}

